import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[wrCropped]',
  standalone: true
})
export class CroppedDirective {
  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.el.nativeElement, 'cropped');
  }
}
