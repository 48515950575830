import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map } from 'rxjs';
import { ApiService } from '../services/api.service';
import { FilmColor } from '../types/film-color.type';

export const filmColorsResolver: ResolveFn<FilmColor[]> = () => {
  return inject(ApiService)
    .getFilmColors()
    .pipe(map((colors: FilmColor[]) => colors.sort((a, b) => a.filmCategory - b.filmCategory)));
};
