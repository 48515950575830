export const environment = {
    production: false,
    cdnUrl: 'https://d2cpb1cf0fpogz.cloudfront.net',
    apiUrl: 'https://bnuz86q6xc.execute-api.us-east-1.amazonaws.com',
    stripePaymentLink: 'https://buy.stripe.com/3csfZF3lLgrJcJG16y',
    gtmId: 'GTM-T9HP73D',
    previewUrl: 'https://s3.amazonaws.com/stage-cybertruck.wrapmate.com/thumbnails/preview',
    ogUrl: 'https://stage-cybertruck.wrapmate.com/configurator?color=',
    impactPage: 'https://stage.wrapmate.com/express/impact/tesla/2023/cybertruck/tesla_2023_cybertruck_truck_base/coverage/large/roof/personal/'

  };
  