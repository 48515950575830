import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { CroppedDirective } from 'src/app/directives/cropped.directive';
import { PreloaderService } from 'src/app/services/preloader.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'wr-preloader',
  standalone: true,
  imports: [CommonModule, CroppedDirective, BrowserAnimationsModule],
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeOut', [
      state(
        'notFaded',
        style({
          opacity: 1
        })
      ),
      state(
        'faded',
        style({
          opacity: 0
        })
      ),
      transition('notFaded => faded', [animate('1s')])
    ])
  ]
})
export class PreloaderComponent implements OnInit, OnDestroy {
  @ViewChild('preloader') preloader!: ElementRef;

  destroy$: Subject<boolean>;

  isSmallScreen$: Observable<boolean>;
  isPreloaderVisible$: Observable<boolean>;
  isPreloaderVisibleOnce$: Observable<true>;
  preloaderProgress$: Observable<number>;

  constructor(
    private preloaderService: PreloaderService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private uiService: UiService,
    private cdr: ChangeDetectorRef
  ) {
    this.destroy$ = new Subject<boolean>();
    this.isSmallScreen$ = this.uiService.isSmallScreen$;
    this.isPreloaderVisible$ = this.preloaderService.isPreloaderVisible$;
    this.isPreloaderVisibleOnce$ = this.preloaderService.isPreloaderVisible$.pipe(
      filter(Boolean),
      take(1)
    );
    this.preloaderProgress$ = this.preloaderService.preloaderProgress$.pipe(
      map(progress => Math.floor(progress)),
      distinctUntilChanged()
    );
  }

  private _polygon = 'polygon(0 0, 0 0, 0 0, 0 0)';

  get polygon() {
    return this.sanitizer.bypassSecurityTrustStyle(this._polygon);
  }

  ngOnInit(): void {
    this.preloaderProgress$.pipe(takeUntil(this.destroy$)).subscribe(progress => {
      this._polygon = `polygon(0 0, ${progress}% 0, ${progress - 4}% 100%, 0 100%)`;

      if (progress == 100) {
        this._polygon = `polygon(0 0, 100% 0, 100% 100%, 0 100%)`;
      }

      this.cdr.markForCheck();
    });
  }

  onFadeOutDone(event: AnimationEvent) {
    if (event.fromState === 'notFaded' && event.toState === 'faded') {
      this.renderer.setStyle(this.preloader.nativeElement, 'display', 'none');
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
