import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { FilmType } from '../types/film-type.type';
import { FilmColor } from '../types/film-color.type';
import { handleError } from '../shared/helpers/handle-error';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getFilmTypes(): Observable<FilmType[]> {
    const url = '../../assets/data/film-types.json';
    return this.http.get<FilmType[]>(url).pipe(catchError(handleError));
  }
  getFilmColors(): Observable<FilmColor[]> {
    const url = '../../assets/data/film-colors.json';
    return this.http.get<FilmColor[]>(url).pipe(catchError(handleError));
  }
}
