import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { map } from 'rxjs';
import { ApiService } from '../services/api.service';
import { FilmType } from '../types/film-type.type';

export const filmTypesResolver: ResolveFn<FilmType[]> = () => {
  return inject(ApiService)
    .getFilmTypes()
    .pipe(map((types: FilmType[]) => types.sort((a, b) => a.id - b.id)));
};
