import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { distinctUntilChanged, filter, map } from 'rxjs';

export const WR_SCREEN_SIZES = {
  SMALL: '(max-width: 767px)',
  MEDIUM: '(min-width: 768px)'
};

@Injectable({
  providedIn: 'root'
})
export class UiService {
  readonly breakpoint$ = this.breakpointObserver
    .observe([WR_SCREEN_SIZES.SMALL, WR_SCREEN_SIZES.MEDIUM])
    .pipe(
      distinctUntilChanged(),
      filter(value => value.matches)
    );

  readonly isSmallScreen$ = this.breakpoint$.pipe(
    map(state => {
      const query = Object.keys(state.breakpoints).find(q => state.breakpoints[q]);
      return query === WR_SCREEN_SIZES.SMALL;
    })
  );

  readonly isSmallScreenValue = this.breakpointObserver.isMatched(WR_SCREEN_SIZES.SMALL);

  constructor(private breakpointObserver: BreakpointObserver) {}

  isMatchedQuery(value: string | readonly string[]): boolean {
    return this.breakpointObserver.isMatched(value);
  }
}
