import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { CroppedDirective } from 'src/app/directives/cropped.directive';
import { UiService } from 'src/app/services/ui.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wr-landing',
  standalone: true,
  imports: [CommonModule, CroppedDirective, RouterModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandingComponent {
  cdnUrl = environment.cdnUrl;
  isSmallScreen$ = this.uiService.isSmallScreen$;

  constructor(private uiService: UiService) {}

}
