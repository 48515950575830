import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { filmTypesResolver } from './resolvers/film-types.resolver';
import { filmColorsResolver } from './resolvers/film-colors.resolver';

const routes: Routes = [
  { 
    path: '', component: LandingComponent,
  },
  {
    path: 'configurator',
    loadComponent: () => import('./pages/main/main.component').then(mod => mod.MainComponent),
    resolve: { filmTypes: filmTypesResolver, filmColors: filmColorsResolver }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}