import { ChangeDetectionStrategy, Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

  queryParamsSubscription: Subscription | undefined;

  constructor(@Inject(DOCUMENT) private document: Document, private meta: Meta, private route: ActivatedRoute) {
    this.meta.addTag({
      name: 'description',
      content: 'Wrapmate Cybertruck configurator'
    });
  };

  ngOnInit() {
    this.queryParamsSubscription = this.route.queryParams.subscribe((params: Params) => {
      this.handleQueryParamChange(params);
    });
  }

  handleQueryParamChange(params: Params) {
    // Implement your logic here to handle the query parameter change
    const metaTagTwitter = this.document.querySelector('meta[name="twitter:image"]');
    const metaTagOGImage = this.document.querySelector('meta[property="og:image"]');
    const metaTagOGURL = this.document.querySelector('meta[property="og:url"]');
    let previewColor = "color-military-green"
    if (params['color']) {
      previewColor = params['color'].replace("color", "");
    }

    if (metaTagTwitter) {
      metaTagTwitter.setAttribute('content', `${environment.previewUrl}${previewColor}.jpg`);
    }
    if (metaTagOGImage) {
      metaTagOGImage.setAttribute('content', `${environment.previewUrl}${previewColor}.jpg`);
    }
    if (metaTagOGURL) {
      metaTagOGURL.setAttribute('content', `${environment.ogUrl}${params['color']}`);
    }
  }

  ngOnDestroy() {
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

}