import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {
  private isPreloaderVisibleSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private preloaderProgressSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  readonly isPreloaderVisible$: Observable<boolean> = this.isPreloaderVisibleSubject.asObservable();
  readonly preloaderProgress$: Observable<number> = this.preloaderProgressSubject.asObservable();

  constructor() {}

  updatePreloaderProgress(value: number) {
    this.preloaderProgressSubject.next(value);
  }

  showPreloader() {
    this.isPreloaderVisibleSubject.next(true);
  }

  hidePreloader() {
    this.isPreloaderVisibleSubject.next(false);
  }
}
